@media screen and (min-width: 992px) {
  .cs-height_0 {
    height: 0px;
  }

  .cs-height_5 {
    height: 5px;
  }

  .cs-height_10 {
    height: 10px;
  }

  .cs-height_15 {
    height: 15px;
  }

  .cs-height_20 {
    height: 20px;
  }

  .cs-height_25 {
    height: 25px;
  }

  .cs-height_30 {
    height: 30px;
  }

  .cs-height_35 {
    height: 35px;
  }

  .cs-height_40 {
    height: 40px;
  }

  .cs-height_45 {
    height: 45px;
  }

  .cs-height_50 {
    height: 50px;
  }

  .cs-height_55 {
    height: 55px;
  }

  .cs-height_60 {
    height: 60px;
  }

  .cs-height_65 {
    height: 65px;
  }

  .cs-height_70 {
    height: 70px;
  }

  .cs-height_75 {
    height: 75px;
  }

  .cs-height_80 {
    height: 80px;
  }

  .cs-height_85 {
    height: 85px;
  }

  .cs-height_90 {
    height: 90px;
  }

  .cs-height_95 {
    height: 95px;
  }

  .cs-height_100 {
    height: 100px;
  }

  .cs-height_105 {
    height: 105px;
  }

  .cs-height_110 {
    height: 110px;
  }

  .cs-height_115 {
    height: 115px;
  }

  .cs-height_120 {
    height: 120px;
  }

  .cs-height_125 {
    height: 125px;
  }

  .cs-height_130 {
    height: 130px;
  }

  .cs-height_135 {
    height: 135px;
  }

  .cs-height_140 {
    height: 140px;
  }

  .cs-height_145 {
    height: 145px;
  }

  .cs-height_150 {
    height: 150px;
  }

  .cs-height_155 {
    height: 155px;
  }

  .cs-height_160 {
    height: 160px;
  }

  .cs-height_165 {
    height: 165px;
  }

  .cs-height_170 {
    height: 170px;
  }

  .cs-height_175 {
    height: 175px;
  }

  .cs-height_180 {
    height: 180px;
  }

  .cs-height_185 {
    height: 185px;
  }

  .cs-height_190 {
    height: 190px;
  }

  .cs-height_195 {
    height: 195px;
  }

  .cs-height_200 {
    height: 200px;
  }

  .cs-height_205 {
    height: 205px;
  }

  .cs-height_210 {
    height: 210px;
  }
}

@media screen and (max-width: 995px) {
  .cs-height_lg_0 {
    height: 0px;
  }

  .cs-height_lg_5 {
    height: 5px;
  }

  .cs-height_lg_10 {
    height: 10px;
  }

  .cs-height_lg_15 {
    height: 15px;
  }

  .cs-height_lg_20 {
    height: 20px;
  }

  .cs-height_lg_25 {
    height: 25px;
  }

  .cs-height_lg_30 {
    height: 30px;
  }

  .cs-height_lg_35 {
    height: 35px;
  }

  .cs-height_lg_40 {
    height: 40px;
  }

  .cs-height_lg_45 {
    height: 45px;
  }

  .cs-height_lg_50 {
    height: 50px;
  }

  .cs-height_lg_55 {
    height: 55px;
  }

  .cs-height_lg_60 {
    height: 60px;
  }

  .cs-height_lg_65 {
    height: 65px;
  }

  .cs-height_lg_70 {
    height: 70px;
  }

  .cs-height_lg_75 {
    height: 75px;
  }

  .cs-height_lg_80 {
    height: 80px;
  }

  .cs-height_lg_85 {
    height: 85px;
  }

  .cs-height_lg_90 {
    height: 90px;
  }

  .cs-height_lg_95 {
    height: 95px;
  }

  .cs-height_lg_100 {
    height: 100px;
  }

  .cs-height_lg_105 {
    height: 105px;
  }

  .cs-height_lg_110 {
    height: 110px;
  }

  .cs-height_lg_115 {
    height: 115px;
  }

  .cs-height_lg_120 {
    height: 120px;
  }

  .cs-height_lg_125 {
    height: 125px;
  }

  .cs-height_lg_130 {
    height: 130px;
  }

  .cs-height_lg_135 {
    height: 135px;
  }

  .cs-height_lg_140 {
    height: 140px;
  }

  .cs-height_lg_145 {
    height: 145px;
  }

  .cs-height_lg_150 {
    height: 150px;
  }

  .cs-height_lg_155 {
    height: 155px;
  }

  .cs-height_lg_160 {
    height: 160px;
  }

  .cs-height_lg_165 {
    height: 165px;
  }

  .cs-height_lg_170 {
    height: 170px;
  }

  .cs-height_lg_175 {
    height: 175px;
  }

  .cs-height_lg_180 {
    height: 180px;
  }

  .cs-height_lg_185 {
    height: 185px;
  }

  .cs-height_lg_190 {
    height: 190px;
  }

  .cs-height_lg_195 {
    height: 195px;
  }

  .cs-height_lg_200 {
    height: 200px;
  }

  .cs-height_lg_205 {
    height: 205px;
  }

  .cs-height_lg_210 {
    height: 210px;
  }
}
