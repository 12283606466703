@import "../../scss/default/color_variable";
.cs-team.cs-style1 {
  overflow: hidden;
  position: relative;
  .cs-member_thumb {
    border-radius: 15px;
    position: relative;
    overflow: inherit;
    overflow: hidden;
    img {
      width: 100%;
      position: relative;
      z-index: 0.5;
      border-radius: inherit;
      transition: all 0.8s ease;
      // opacity: 0.7;
    }
  }



 
  
  .cs-member_overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: inherit;
    background: linear-gradient(360deg, #000000 0%, rgba(77, 77, 77, 0) 100%);
  }
  .cs-member_social {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .cs-member_name {
    font-size: 22px;
    margin-bottom: 6px;
  }
  .cs-member_info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    padding: 30px 25px;
  }
  .cs-member_social {
    position: absolute;
    z-index: 2;
    left: 13px;
    top: 50px;
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      margin: 12px;
      transform: scale(0) rotate(45deg);
      opacity: 0;
      transition: all 0.4s ease;
    }
  }
  &:hover {
    .cs-member_social {
      a {
        transform: scale(1) rotate(0deg);
        opacity: 1;
      }
    }
    .cs-member_thumb {
      img {
        transform: scale(1.06) translateY(8px);
      }
    }
     .cs-member_name,.cs-member_designation{
      display: none !important;
    }
    .cs-member_thumb img{
      z-index: 1; 
    }
    .color-image{
      display: block !important;
    }
    .black-image{
      display: none !important;
    }
    


  }
}



.cs-team.hover-remove {
  overflow: hidden;
  position: relative;
  .cs-member_thumb {
    border-radius: 15px;
    position: relative;
    overflow: inherit;
    overflow: hidden;
    img {
      width: 100%;
      position: relative;
      z-index: 0.5;
      border-radius: inherit;
      transition: all 0.8s ease;
      // opacity: 0.7;
    }
  }
  .cs-member_overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: inherit;
    background: linear-gradient(360deg, #000000 0%, rgba(77, 77, 77, 0) 100%);
  }
  .cs-member_social {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .cs-member_name {
    font-size: 22px;
    margin-bottom: 6px;
  }
  .cs-member_info {
    position: absolute;
    left: 0;
    bottom: 20%;
    width: 100%;
    z-index: 3;
    padding: 30px;
  }
  .cs-member_social {
    position: absolute;
    z-index: 2;
    left: 13px;
    top: 50px;
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      margin: 12px;
      transform: scale(0) rotate(45deg);
      opacity: 0;
      transition: all 0.4s ease;
    }
  }
}
@media screen and (max-width: 1380px) {
.cs-team.cs-style1 .cs-member_name {
  font-size: 20px;
}
}

.color-image{
  display: none !important;
}
// home section code 


// new added 20-06-23
.color-card{
  background-color: #122450;
  height: 200px; 
}
// .slick-slide img {
//   display: none;
// }
.memb-info{
  // padding: 66px 25px !important;
  align-items: center !important;
  justify-content: center !important;
}
.cs-member_content{
  color: rgba(254, 254, 254, 0.8);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}
.cs-member_designation{
  color: rgba(254, 254, 254, 0.7);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}
.change-videos-size{
  width:100%;
  height: auto;
}
.memeber_name{
  font-size: 25px;
}

@media (min-width: 320px) and (max-width: 770px) {
  .change-videos-size{
    height:400px !important;
  }
 }

.rectan-videos{
  height:630px !important;

  width:100%;
  object-fit: cover;

}
.rectan{

  width:100%;
}