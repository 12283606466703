.tabs_custom{
    background-color: #191919 !important;
    width:100%;
    height:100%;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    border-radius: 0.5rem;
     padding:10px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-family:"Poppins",sans-serif;
    margin: 10px;
    text-align: center;
    align-items: center;

}
.tab.active {
    background-color:#ffa801;
    /* Add any other styling you want for the active tab */
    border-radius: 0.5rem;
    padding:12px;
    color: #fefefe;
    margin: 0%;
    
    
  }
  .tabs_videos_custom{
    width:100%;
  }
  .what_we_do_box{
    background-color: #191919 !important;
    width:100%;
    height:100%;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    padding: 20px;
    border-radius: 5px;
  }